import { useState, useEffect, useRef } from 'react'
import valueObjects from './services/values';
import suitObjects from './services/suits';
import Values from "./components/Values";
import Suits from './components/Suits';
import Hand from './components/Hand'
import Score from './components/Score'
import Header from './components/Header'
import Footer from './components/Footer';

const generateId = () =>
  Number((Math.random() * 1000000).toFixed(0))

const App = () => {
  const [values] = useState(valueObjects)
  const [selectedValue, setSelectedValue] = useState(null)

  const [suits] = useState(suitObjects)
  const [selectedSuit, setSelectedSuit] = useState(suits[0])

  const [sharedCard, setSharedCard] = useState(null)
  const [hand, setHand] = useState([])

  const addCard = value => {
    const playingCard = {
      id: `${value.value}+${selectedSuit.suit}`,
      value: value.value,
      suit: selectedSuit.suit
    }
    console.log(playingCard.id)
    if (sharedCard === null) {
      setSharedCard(playingCard)
    }
    if (hand.length < 5) {
      const temp = hand.find(card => card.id === playingCard.id)
      if (!temp) {
        setHand(hand.concat(playingCard))
      }
    }
  }

  const handleValueSelect = id => {
    const value = values.find(v => v.id === id)
    setSelectedValue(value)
    addCard(value)
  }

  const handleSuitSelect = id => {
    const suit = suits.find(s => s.id === id)
    setSelectedSuit(suit)
  }

  const handleRemoveCard = id => {
    setHand(hand.filter(card => card.id !== id))
  }

  return (
    <>
      <div className='flexbox-container-header-footer'>
        <Header />
      </div>
      <div className='flexbox-container-1'>
        <Suits suits={suits} handleSuitSelect={handleSuitSelect} />
        <Values values={values} handleValueSelect={handleValueSelect} />
        <Score hand={hand} sharedCard={sharedCard} />
      </div>
      <div className='flexbox-container-2'>
        <Hand hand={hand} handleRemoveCard={handleRemoveCard} />
      </div>
      <div className='flexbox-container-header-footer'>
        <Footer />
      </div>
    </>
  )
}

export default App;
