import React from 'react'

const Value = ( {value, handleValueSelect} ) => {
  return (
    <div className='container'>
      <div className='value'>
        <div value={value.id} onClick={handleValueSelect}>{value.value}</div>
      </div>
    </div>
  )
}

export default Value