const valueObjects = [
  {
    'id': 1,
    'value': 'ace'
  }, 
  {
    'id': 2,
    'value': '2'
  }, 
  {
    'id': 3,
    'value': '3'
  }, 
  {
    'id': 4,
    'value': '4'
  }, 
  {
    'id': 5,
    'value': '5'
  }, 
  {
    'id': 6,
    'value': '6'
  }, 
  {
    'id': 7,
    'value': '7'
  }, 
  {
    'id': 8,
    'value': '8'
  }, 
  {
    'id': 9,
    'value': '9'
  }, 
  {
    'id': 10,
    'value': '10'
  }, 
  {
    'id': 11,
    'value': 'jack'
  }, 
  {
    'id': 12,
    'value': 'queen'
  },
  {
    'id': 13,
    'value': 'king'
  }, 
]

export default valueObjects

