const suitObjects = [
  {
    'id': 1,
    'suit': 'hearts'
  },
  {
    'id': 2,
    'suit': 'diamonds'
  },
  {
    'id': 3,
    'suit': 'spades'
  },
  {
    'id': 4,
    'suit': 'clubs'
  },
]

export default suitObjects