import React from 'react'

const Header = () => {
  return (
    <div>
      <div>
        <h1>Count 29</h1>
      </div>
      <div>
        <h4>Your handy cribbage scorer</h4>
      </div>
    </div>
  )
}

export default Header