import React from 'react'
import Suit from './Suit'

const Suits = ( {suits, handleSuitSelect} ) => {
  return (
    <div className='flexbox-item-1'>
      <h3>Suit</h3>
      {suits
        .map(suit => {
          return (
            <Suit key={suit.id} suit={suit} handleSuitSelect={() => handleSuitSelect(suit.id)} />
          )
        })
      }
    </div>
  )
}

export default Suits