import React from 'react'
import Value from './Value'

const Values = ({ values, handleValueSelect }) => {
  return (
    <div className='flexbox-item-1'>
      <h3>Face</h3>
      {values
        .map(value => {
          return (
            <Value key={value.id} value={value} handleValueSelect={() => handleValueSelect(value.id)} />
          )
        })
      }
    </div>
  )
}

export default Values
