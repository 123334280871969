import React from 'react'

const Suit = ( {suit, handleSuitSelect} ) => {
  return (
    <div className='container'>
      <div value={suit.id}>
        <img className='suit' 
          src={`${process.env.PUBLIC_URL}/assets/icons/${suit.suit}.png`} 
          alt='card_img' 
          onClick={handleSuitSelect} />
      </div>
    </div>
  )
}

export default Suit