import React from 'react'

const calcFifteens = (hand, index, value) => {
  if (index >= hand.length) return 0

  let points = 0;
  points += calcFifteens(hand, index + 1, value)
  value += convertValueForFifteens(hand[index].value)

  if (value === 15) {
    points += 2
  }
  
  points += calcFifteens(hand, index + 1, value)

  return points
}

const calcPairs = (hand) => {
  let pairs = []
  hand.forEach( (card, index, array) => {
    for (let i = index + 1; i < array.length; i++) {
      if (card.value === array[i].value) {
        pairs.push([card, array[i]]);
      }
    }
  })

  return pairs.length * 2
}

const calcRuns = (hand) => {
  let points = 0
  let cardValues = []
  let longestRun = 1
  let chain = 1
  let multiplier = 1

  for (let i = 0; i < hand.length; i++) {
    let value = hand[i].value
    cardValues.push(convertValueForRuns(value))
  }

  cardValues.sort((a, b) => (a - b))

  for (let i = 1; i < cardValues.length; i++) {
    if (cardValues[i - 1] === cardValues[i] - 1) {
      chain++
      if (chain > longestRun) {
        longestRun = chain
      }
    } else if (cardValues[i - 1] === cardValues[i]) {
      if (i >= 2 && cardValues[i - 2] === cardValues[i]) {
        multiplier++
      } else {
        multiplier *= 2
      }
    } else {
      chain = 1
    }
  }

  if (longestRun > 2) {
    points = longestRun
  }

  return points * multiplier
}

const calcNobs = (hand, sharedCard) => {
  const nobMatch = hand.find(card => card.value === 'jack' && card.suit === sharedCard.suit)

  if (nobMatch) {
    return 1
  } else {
    return 0
  }
}

const calcFlush = (hand) => {
  let points = 0
  let suitMap = {}

  for (let i = 1; i < hand.length; i++) {
    let suit = hand[i].suit
    if (suitMap[suit] !== undefined) {
      suitMap[suit]++
      if (i === 4 && hand[0].suit === suit) {
        suitMap[suit]++
      }
    } else {
      suitMap[suit] = 1
    }
  }

  for (let key in suitMap) {
    switch(suitMap[key]) {
      case 4:
        points = 4
        break
      case 5:
        points = 5
        break
      default:
        points = 0
    }
  }
  return points
}

const convertValueForFifteens = (value) => {
  switch(value) {
    case 'ace':
      return 1
    case 'jack':
    case 'queen':
    case 'king':
      return 10
    default:
      return parseInt(value)
  }
}

const convertValueForRuns = (value) => {
  switch(value) {
    case 'ace':
      return 1
    case 'jack':
      return 11
    case 'queen':
      return 12
    case 'king':
      return 13
    default:
      return parseInt(value)
  }
}

const Score = ({ hand, sharedCard }) => {
  const fifteens = calcFifteens(hand, 0, 0)
  const pairs = calcPairs(hand)
  const runs = calcRuns(hand)
  const nobs = calcNobs(hand, sharedCard)
  const flush = calcFlush(hand)
  const total = fifteens + pairs + runs + nobs + flush

  return (
    <div className='flexbox-item-1'>
      <h3>Score</h3>
      <p>15s: {fifteens}</p>
      <p>Pairs: {pairs}</p>
      <p>Runs: {runs}</p>
      <p>Flush: {flush}</p>
      <p>Nobs: {nobs}</p>
      <p><strong style={{ backgroundColor: '#CAF0F8'}}>Total: {total}</strong></p>
    </div>
  )
}

export default Score