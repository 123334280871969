import React from 'react'

const Footer = () => {
  return (
    <div>
      <div>
        Made so you can prove you're right by <a href='https://www.heathmaag.com/'>Heath</a>
      </div>
    </div>
  )
}

export default Footer