import React from 'react'
import Card from './Card'

const Hand = ({ hand, handleRemoveCard }) => {
  return (
    <div className='flexbox-item-2'>
      {hand
        .map(card => {
          return (
            <Card key={card.id} card={card} handleRemoveCard={() => handleRemoveCard(card.id)} />
          )
        })
      }
    </div>
  )
}

export default Hand