import React from 'react'

const Card = ( { card, handleRemoveCard } ) => {
  return (
    <span>
      <img className="playing-card" 
        src={`${process.env.PUBLIC_URL}/assets/playing-cards/png/${card.value}_of_${card.suit}.png`} 
        alt='card_img' 
        onClick={handleRemoveCard} />
    </span>
  )
}

export default Card